import { useContext } from 'react';
import { AmplitudeContext } from '@/contexts/amplitude';

export interface CMSEventParams {
  name: string;
  description: string;
  [key: string]: string | number;
}

export interface CMSEventReference {
  section: string;
  cta?: string;
}

export function useAnalytics() {
  const { analytics } = useContext(AmplitudeContext);

  return {
    async sendEvents(events: CMSEventParams[], reference?: CMSEventReference) {
      return events.map(event => {
        const { name, description, ...customProperties } = event;
        analytics?.track({
          event_type: name,
          event_properties: {
            name: name,
            description: description,
            section_reference: reference?.section,
            cta_reference: reference?.cta,
            ...customProperties,
          },
        });
      });
    },
  };
}
