export const LINKS = {
  NOT_CLIENT_PHONE: 'tel:30049681',
  NOT_CLIENT_WHATSAPP: '/adquira/?defaultChannel=whatsapp',
  NOT_CLIENT_CHAT: '/adquira/?defaultChannel=chat',
  CLIENT_PHONE: 'tel:30049680',
  CLIENT_WHATSAPP:
    'https://api.whatsapp.com/send?phone=551130049680&text=Olá, você pode me ajudar?',
  HELP_CENTER: 'https://ajuda.stone.com.br/',
  APP_LINK: 'https://app.adjust.com/f35jfea',
  APP_LINK_QR_CODE: 'https://app.adjust.com/fn8hx2m',
  APP_LINK_PRODUTOS_STONE: 'https://app.adjust.com/17wjo7l9',
  APP_LINK_IOS: 'https://apps.apple.com/br/app/conta-stone/id1438680035',
  APP_LINK_ANDROID:
    'https://play.google.com/store/apps/details?id=co.stone.banking.mobile.flagship',
  APP_LINK_APPSFLYER_QR_CODE_HOME:
    'https://stone-prod.onelink.me/P4cc/uf0uq07e',
  APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ:
    'https://stone-prod.onelink.me/P4cc/rfgl88ux',
  APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ_BUTTON_FLOAT:
    'https://stone-prod.onelink.me/P4cc/ptje2hso',
  APP_LINK_APPSFLYER_QR_CODE_MASTERCHEF:
    'https://stone-prod.onelink.me/P4cc/4r0qxv7h',
  APP_LINK_APPSFLYER_QR_CODE_MASTERCHEF_BUTTON_FLOAT:
    'https://stone-prod.onelink.me/P4cc/kpv34j4h',
  APP_LINK_APPSFLYER_QR_CODE_WHATSAPP:
    'https://stone-prod.onelink.me/P4cc/o1zf5ary',
  APP_LINK_APPSFLYER_QR_CODE_CARTAO:
    'https://stone-prod.onelink.me/P4cc/5m1n7ud7',
  APP_LINK_APPSFLYER_QR_CODE_LINK_PAGAMENTO:
    'https://stone-prod.onelink.me/P4cc/o1aaxwcw',
  APP_LINK_APPSFLYER_QR_CODE_GESTAO:
    'https://stone-prod.onelink.me/P4cc/2ctaqj9n',
  APP_LINK_APPSFLYER_QR_CODE_PRODUTOS_STONE:
    'https://stone-prod.onelink.me/P4cc/umj2c00n',
  APP_LINK_APPSFLYER_QR_CODE_PLANOS:
    'https://stone-prod.onelink.me/P4cc/y10lpr3r',
  APP_LINK_APPSFLYER_QR_CODE_PLANOS_MGM:
    'https://stone-prod.onelink.me/P4cc/ng9sap5w',
  APP_LINK_APPSFLYER_QR_CODE_GESTAO_CONTROLE:
    'https://stone-prod.onelink.me/P4cc/2ctaqj9n',
  APP_LINK_APPSFLYER_QR_MENU: 'https://stone-prod.onelink.me/P4cc/yo22ww8c',
  APP_LINK_APPSFLYER_EXPERIMENT_ACCOUNT:
    'https://stone-prod.onelink.me/P4cc/w0ismgkb',
  APP_LINK_APPSFLYER_RESERVA_STONE:
    'https://stone-prod.onelink.me/P4cc/28off9q6',
  PAPO_DE_BALCAO: 'https://papodebalcao.stone.com.br/',
  BLOG: 'https://blog.stone.com.br/',
  INBOUND: '/adquira/',
  ACCOUNT: 'https://conta.stone.com.br/',
  TON: 'https://www.ton.com.br/',
  MEGATON_REGULATION:
    'https://registon.api.ton.com.br/documents/regulamento-megaton/latest',
  GIGATON_REGULATION:
    'https://registon.api.ton.com.br/documents/regulamento-gigaton/latest',
  STORE_INSURANCE_CONTACT:
    'https://api.whatsapp.com/send?phone=551130049680&text=Recebi%20uma%20oferta%20de%20prote%C3%A7%C3%A3o%20financeira',
  STORE_INSURANCE_CONTACT_V2: 'https://lojastone.seguros.vitta.com.br/',
  STORE_INSURANCE_LIFE_INSURANCE:
    'https://api.whatsapp.com/send?phone=551130049680&text=Recebi%20uma%20oferta%20de%20seguro%20de%20vida',
  CLOUDINARY_URL_VIDEO:
    'https://res.cloudinary.com/dunz5zfpt/video/upload/f_auto:video,q_auto',
  CLOUDINARY_URL: 'https://res.cloudinary.com/dunz5zfpt',
  WHATSAPP_PAY: 'https://wa.me/pay/virality/4e50ff15e8',
  GIRO_FACIL_CREDIT_STONE: 'https://conta.stone.com.br/deeplink/home',
  CLIENT_LIBRAS: 'https://pessoalize.callvideo.io/stone',
  PRIVACY_POLICY: 'https://docs.stone.com.br/aviso-de-privacidade/',
  CONTENT: 'https://conteudo.stone.com.br/',
};

export default LINKS;
