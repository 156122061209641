import { useSearchParams } from 'next/navigation';

import { UrlObject } from 'url';

interface Args {
  href?: string;
  query?: string;
  hash?: string;
  pathname?: string;
}

export function useCMSLink() {
  const searchParams = useSearchParams();

  function getHrefFromCMSParams(args: Args): string | UrlObject {
    let href,
      pathname = null;

    const cmsOrCurrentQuery = args?.query
      ? args.query
      : searchParams?.toString();

    if (args.href) {
      const queryString = !cmsOrCurrentQuery
        ? ''
        : cmsOrCurrentQuery.startsWith('?')
        ? cmsOrCurrentQuery
        : `?${cmsOrCurrentQuery}`;

      return `${args.href}${args.pathname || ''}${
        args.hash || ''
      }${queryString}`;
    } else if (args.pathname) {
      pathname = args.pathname;
    }

    const url = {
      href,
      pathname,
      query: cmsOrCurrentQuery,
      hash: args.hash,
    };

    return url;
  }

  return { getHrefFromCMSParams };
}
